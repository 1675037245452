import './App.css';
import description from './description.png';
import logo from './logo.png';

function App() {
  return (
    <div className="App">
      <div className="App-container">
        <div className="App-title">
          <img src={logo} className="App-logo" alt="logo"/>
          <h1>Votre avenir financier commence ici (coming soon...)</h1>
        </div> 
        <img src={description} className="App-description" alt="description"/>
        <iframe title="forms.app" className="App-poll" allowtransparency="true" allowfullscreen="true" allow="geolocation; microphone; camera" src="https://my.forms.app/form/660051c844e8ee4e971955c7" frameborder="0"></iframe>
      </div>
    </div>
  );
}

export default App;
